.productDetailsScreen {
    /* border: 2px solid red; */
}

.productDetailsScreen .filterSection {
    /* border: 2px solid blue; */
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
    background-color: white;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:10px;
}
.productDetailsScreen .filterSection > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
}

.productDetailsScreen .filterSection > div .select-container {
    width: 100%;
}

.productDetailsScreen .filterSection > div > span {
    text-wrap: nowrap;
}

.productDetailsScreen .tableSection {
    /* border: 2px solid blue; */
    border-radius: 10px;
    margin: 10px;
    padding: 20px;
    background-color: white;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    
    /* position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 8rem;
    display: flex;
    justify-content: center;
    align-items: center; */
    height: 80vh;
    position: relative;
    overflow-y: scroll;
}
.productDetailsScreen .tableSection::-webkit-scrollbar {
    display: none;
}

.productDetailsScreen .tableSection .paginationSection {
    /* border: 2px solid blue;/ */
    display: flex;
    justify-content: center;
    gap: 20px;
    background-color:#d7d7d7;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    /* position: absolute;
    bottom: 0;
    left: 0;
    right: 0; */
    padding: 5px;
}

.productDetailsScreen .tableSection .paginationSection .pageNoCont{
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #a4aab0;
}

.productDetailsScreen .tableSection .paginationSection .pageNoCont .pageIn{
    border: none;
    padding: 5px;
    width: 80px;
    text-align: center;
    border-radius: 5px;
    background-color: white;
    appearance: none;
}