.productPageCont {
    margin-top: 20px;
    /* border: 2px solid red; */
    text-align: left;
    display: flex;
}

.productPageCont .section1 {
    flex: 45%;
}

.productPageCont .section1 .imageCont { 
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    background-color: white;
    border-radius: 10px;
    margin: 5px;
    padding: 10px;

    display: flex;
    flex-direction: column;
}

.productPageCont .section1 .imageCont .carousel-indicators{
    bottom: -10px;
}

.productPageCont .section1 .imageCont .carousel-indicators button{
    background-color: #bbb;
    width: 8px;
    height: 8px;
    margin: 2px;
    border: none;
    padding: unset;
    border-radius: 50%;
    display: inline-block;
    outline: unset;
}

.productPageCont .section1 .imageCont .carousel-indicators button.active{
    outline: unset;
    background-color: #1e1b1b;
}

.productPageCont .section1 .imageCont .carousel-control-next, .carousel-control-prev {
    margin-top: auto;
    margin-bottom: auto;
    height: 200px;
}

.carousel-control-next, .carousel-control-prev {
    /* padding: 2px;
    border-radius: 4px;
    background-color: white !important;
    */
    /* padding: 5px; */
    /* height: fit-content !important; */
    height: 20px !important;
    width: 20px !important;
    /* width: fit-content !important;  */
    margin: 0 !important;
    margin-top: auto !important;
    margin-bottom: 5px !important;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
    filter: invert(1);
}


.productPageCont .section1 .imageCont .imgCont{
    width: 100%;
    height: 65vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.productPageCont .section1 .imageCont .imgCont .imgBox{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    pointer-events: none;
    user-select: none;
}
.productPageCont .section1 .imageCont .imgCont .vidBox{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    user-select: none;
}

.productPageCont .section1 .productCont{
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    background-color: white;
    border-radius: 10px;
    margin: 5px;
    margin-top: 10px;
    padding: 10px;
    
}

.productPageCont .section2 {
    flex: 45%;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    background-color: white;
    border-radius: 10px;
    margin: 5px;
    padding: 10px;
}

.productPageCont .colorOptions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3px;
}

.productPageCont .colorOptions .colorOp {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin: 2px;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    cursor: pointer;
}

.productPageCont .colorOptions .colorOp.selected {
    border: 2px solid black;
    scale: 1.2;
}

.productPageCont .colorOptions .colorOp:hover {
    border: 2px solid black;
    scale: 1.2;
}

.productPageCont .downloadBtn {
    position: absolute;
    right: 0;
    width: 30px;
    height: 30px;
    user-select: none;
    cursor: pointer;
}

.productPageCont .imgNameSpan {
    position: absolute;
    left: 0;
    width: 30px;
    height: 30px;
    user-select: none;
    cursor: pointer;
    text-wrap: nowrap;
}