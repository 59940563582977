.select-container {
  position: relative;
  /* width: 95%; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-container .options-list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid rgba(0,0,0,.125);
  background-color: #fff;
}

.select-container .list-group-item {
  position: relative;
  display: block;
  padding: 2px 10px;
}

.select-container .crossIcon {
  width: 10px;
  height: 10px;
  /* border: 2px solid red; */
  margin-left: 5px;
}

.select-container .crossIcon img{
  width: 100%;
  height: 100%;
  display: flex;
  cursor: pointer;
  transition: all 0.2s;
}

.select-container .crossIcon img:hover{
  transform:rotate(90deg);
}
