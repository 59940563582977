.uploadScreenCont {
    /* border: 2px solid red; */
    /* position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 6rem; */
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.uploadScreenCont .uploadFormCont {
    text-align: left;
    padding: 50px;
    /* border: 5px solid yellow; */
    width: 65%;
    height: 75%;
    display: flex;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

.uploadScreenCont .uploadFormCont table{
    width: 100%;
    border-spacing: 30px;
}